.content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;
    background: rgba(0,0,0,.3);
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentBody{
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: fit-content;
}

.title .head1{
    width: 574px;
    border-top: 5px solid;
    border-bottom: 5px solid;
    text-align: center;
    border-color: #118ed6;
    padding: 10px;
    align-items: center;
    margin: 9px auto;
}

.contentBody .logo img{
    width: 100px;
}

.contentButton button{
    padding: 8px 30px;
    border-radius: 14px;
    cursor: pointer;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    background: #118ed6;
    color: white;
}

.contentButton button:hover{ 
    background-color: #117dd6;
}


.contentFooter{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}