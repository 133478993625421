.slick-prev{
    left: 0;
}
.slick-next{
    right: 0;
}

.slick-prev:before,
.slick-next:before {
  color: #881798;
}

.image img{
    height: calc(100vh);
}

.slick-dots{
    display: none !important;
}