.error {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .home {
        background-color: #0056b3;
        padding: 10px 20px;
        color: white;
        text-decoration: none;
        border-radius: 3px;
        margin-bottom: 1rem;
    }
}