.table{
    width: 100%;
    margin-top: 10px;
}
.head{
    background-color: #1198C4;
    color: white;
}

.row td{
    padding: 5px 10px;
    font-size: 0.875rem;
    text-align: center;
    border-right: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
}

.row td:first-child{
    border-left: 1px solid rgb(224, 224, 224);
}